<template>
  <page-header title="Слова">
    <q-btn color="primary" @click="showImportTermsDialog">
      <img src="../../assets/icons/button/import.svg" class="xs" title="Импорт слов из .xlsx" alt="">
      <span class="gt-xs">Импорт слов из .xlsx</span>
    </q-btn>
    <q-btn color="primary" @click="showAddTermDialog">
      <img src="../../assets/icons/button/add.svg" class="xs" title="Добавить слово" alt="">
      <span class="gt-xs">Добавить слово</span>
    </q-btn>
  </page-header>

  <template v-if="terms !== null">
    <q-card class="q-pa-md q-mb-lg">
      <router-link to="/terms/requests" class="terms-requests-title">{{ termsRequestsTitle }}</router-link>
    </q-card>

    <q-card class="col terms-filter">
      <div class="terms-filter__header">Поиск слова</div>
      <q-input v-model="filterForm.q" type="search" label="Введите слово" outlined clearable autofocus @keyup="setQ" @clear="setQ">
        <template v-slot:prepend>
          <img src="../../assets/icons/search.svg" alt="">
        </template>
      </q-input>

      <div @click="toggleAdvancedSearch" class="terms-filter__toggle" :class="{'terms-filter__toggle--opened': filterForm.showAdvanced}">
        Расширенный поиск
      </div>

      <div v-if="filterForm.showAdvanced" class="terms-filter__advanced">
        <q-select
          v-model="filterForm.category"
          label="Выберите отрасль"
          outlined
          input-debounce="0"
          :options="categoriesOptions"
          map-options
          option-value="id"
          option-label="name"
          @update:model-value="setCategory"
          clearable
        >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ '—'.repeat(scope.opt.level) }} {{ scope.opt.name }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <div class="terms-filter__letters">
          <div class="terms-filter__letters-cyrillic" :style="{'width': (letters.cyrillic.length * 32)+'px'}">
            <button v-for="letter in letters.cyrillic" :key="letter" :name="letter" @click="setLetter(letter)" class="letter" :class="{selected: letter === filter.letter}">
              {{ letter }}
            </button>
          </div>
          <div class="terms-filter__letters-latin" :style="{'width': (letters.cyrillic.length * 32)+'px'}">
            <button v-for="letter in letters.latin" :key="letter" :name="letter" @click="setLetter(letter)" class="letter" :class="{selected: letter === filter.letter}">
              {{ letter }}
            </button>
          </div>
        </div>
      </div>
    </q-card>

    <q-card v-if="terms.length === 0" class="q-pa-lg">
      Ничего не найдено.
    </q-card>
    <template v-else>
      <q-card class="t-table">
        <div v-for="term in terms" :key="term.id" class="t-row">
          <div class="t-field t-field-name">
            <router-link :to="{ name: 'terms_view', params: { id: term.id }}">{{ term.name }}</router-link>
            <i v-if="!term.published" class="material-icons term-unpublished">visibility_off</i>
            <div class="t-field-teaser">
              {{ term.teaser }}
            </div>
          </div>
          <div class="t-field t-field-actions">
            <term-context-menu :term="term" :hook="reload" />
          </div>
        </div>
      </q-card>

      <pagination :page="filter.page" :pages="pages" :to-fn="paginationFn" />
    </template>
  </template>
</template>

<script>
import loading from '../../loading.js'
import { api as termsApi } from '../../api/terms.js'
import { api as termsRequestsApi } from '../../api/termsRequests.js'
import PageHeader from '../../blocks/PageHeader.vue'
import TermContextMenu from '../../blocks/terms/TermContextMenu.vue'
import EditTermDialog from '../../dialogs/terms/EditTermDialog.vue'
import ImportTermsDialog from '../../dialogs/terms/ImportTermsDialog.vue'
import Pagination from '../../blocks/Pagination.vue';
import api from '../../api/api.js'

const createFilter = function(query) {
  const filter = {
    page: 1,
    q: null,
    category: null,
    letter: null,
    isNotEmpty() {
      return this.q || this.letter
    }
  }

  if (query) {
    if (query['page']) {
      filter.page = parseInt(query['page'])
    }
    if (query['q']) {
      filter.q = query['q']
    } else if (query['letter']) {
      filter.letter = query['letter']
    }
    if (query['category']) {
      filter.category = query['category']
    }
  }

  return filter
}

async function preparePage(callback, to) {
  loading.start()

  const categories = await api.categories.findTree()
  const categoriesOptions = []
  const processLevel = categories => {
    categories.forEach(category => {
      categoriesOptions.push(category)
      processLevel(category.children)
    })
  }
  processLevel(categories)

  const letters = await api.terms.getLetters()

  const filter = createFilter(to.query)
  const terms = await termsApi.findBy(filter)
  const termsRequestsTotal = await termsRequestsApi.fetchNewTotal()
  const setInitData = vm => {
    vm.letters = letters
    vm.categoriesOptions = categoriesOptions

    vm.terms = terms
    vm.termsRequestsTotal = termsRequestsTotal
    vm.pages = terms.meta.pages
    vm.setFilter(filter)

    if (filter.letter) {
      vm.filterForm.showAdvanced = true
    }
  }
  callback(setInitData)
  loading.stop()
  loading.updateTitle(to.meta.title || '')
}

export default {
  components: {
    PageHeader,
    TermContextMenu,
    Pagination,
  },
  async beforeRouteEnter (to, from, next) {
    await preparePage(setInitData => {
      next(setInitData)
    }, to)
  },
  async beforeRouteUpdate(to, from, next) {
    await preparePage(setInitData => {
      setInitData(this)
      next()
    }, to)
  },
  data() {
    return {
      terms: null,
      termsRequestsTotal: null,
      filter: {
        page: 1,
      },
      filterForm: {
        q: '',
        category: '',
        letter: '',
        showAdvanced: false,
      },
      letters: [],
      categoriesOptions: [],
      pages: 0,
    }
  },
  methods: {
    setFilter(filter) {
      this.filter = filter
      this.filterForm.q = filter.q
      this.filterForm.category = filter.category
      this.filterForm.letter = filter.letter
    },
    async reload() {
      await this.doSearch()
    },
    async doSearch() {
      const terms = await termsApi.findBy(this.filter)
      this.terms = terms
      this.pages = terms.meta.pages
      this.letters = await api.terms.getLetters()
    },
    updateUrl() {
      const url = new URL(window.location)
      if (this.filter.q) {
        url.searchParams.set('q', this.filter.q)
      } else {
        url.searchParams.delete('q')
      }
      if (this.filter.letter) {
        url.searchParams.set('letter', this.filter.letter)
      } else {
        url.searchParams.delete('letter')
      }
      if (this.filter.category) {
        url.searchParams.set('category', this.filter.category)
      } else {
        url.searchParams.delete('category')
      }
      url.searchParams.delete('page')
      history.replaceState({}, null, url.toString())
    },
    setQ() {
      this.filter.q = this.filterForm.q
      this.filter.letter = null
      this.filter.page = 1
      this.apply()
    },
    setCategory() {
      this.filter.category = this.filterForm.category ? this.filterForm.category.id : null
      this.filter.page = 1
      this.apply()
    },
    setLetter(letter) {
      this.filter.q = null
      this.filter.letter = letter
      this.filter.page = 1
      this.apply()
    },
    apply() {
      this.setFilter(this.filter)
      this.updateUrl()
      this.doSearch()
    },
    toggleAdvancedSearch() {
      if (this.filterForm.showAdvanced) {
        this.filterForm.showAdvanced = false
        this.filter.category = null
        this.filter.letter = null
        this.setFilter(this.filter)
        this.updateUrl()
      } else {
        this.filterForm.showAdvanced = true
      }
    },
    showAddTermDialog() {
      const term = termsApi.create()
      this.$root
        .createDialog({
          component: EditTermDialog,
          componentProps: {
            term: term,
            hook: this.reload,
          },
        })
        .onOk(term => {
          this.$root.showSuccessMessage(`Слово «${term.name}» добавлено.`)
        })
    },
    showImportTermsDialog() {
      this.$root
        .createDialog({
          component: ImportTermsDialog,
          componentProps: {
            hook: this.reload,
          },
        })
        .onOk((lines) => {
          const linesCount = lines.length
          this.$root.showSuccessMessage('Импортировано ' + linesCount + this.pluralize(linesCount, ' слово', ' слова', ' слов') + '.')
        })
    },
    paginationFn(page) {
      const newFilter = {}
      if (page > 1) {
        newFilter.page = page
      }
      if (this.filter.q) {
        newFilter.q = this.filter.q
      }
      if (this.filter.category) {
        newFilter.category = this.filter.category
      }
      if (this.filter.letter) {
        newFilter.letter = this.filter.letter
      }

      return { query: newFilter }
    },
  },
  computed: {
    termsRequestsTitle() {
      let title = 'Предложенные слова'
      if (this.termsRequestsTotal > 0) {
        title += ` (${this.termsRequestsTotal})`
      }
      return title
    },
  }
}
</script>

<style lang="scss">
@import "~@/spa/styles/quasar.variables.scss";

.terms-requests-title {
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
}
.t-field-teaser {
  margin-top: 6px;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
}

.terms-filter {
  padding: 30px;
  margin-bottom: 30px;

  &__header {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 24px;
  }

  &__toggle {
    position: relative;
    display: inline-block;
    padding-right: 20px;
    margin-top: 24px;
    font-weight: 600;
    font-size: 14px;
    color: #2C7DDC;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      right: 0;
      top: 3px;
      border-top: 1px solid #2C7DDC;
      border-right: 1px solid #2C7DDC;
      border-radius: 2px;
      transform: rotate(135deg);
    }

    &--opened {
      &::after {
        transform: rotate(-45deg);
        top: 10px;
      }
    }
  }

  &__advanced {
    margin-top: 30px;
  }

  &__letters {
    text-align: center;
    margin-top: 42px;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 20px;

    @media (max-width: $breakpoint-xs-max) {
      text-align: left;
    }
  }

  &__letters-cyrillic {
    margin: 0 auto 0 auto;
  }

  &__letters-latin {
    margin: 26px auto 10px auto;
  }

  .letter {
    position: relative;
    cursor: pointer;

    width: 28px;
    height: 28px;

    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    border: none;

    margin: 0 2px;

    background: #EFEFEF;
    &.selected {
      background: #2C7DDC;
      color: #FFFFFF;

      &::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        left: 10px;
        top: 24px;

        background: #2C7DDC;
        border-radius: 2px;
        transform: rotate(-45deg);
      }
    }

    &:hover {
      background: #4A98F3;
      color: #FFFFFF;
    }
  }
}
.term-unpublished {
  margin-left: 5px;
  color: gray;
}
</style>
