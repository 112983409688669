<template>
  <q-dialog ref="dialog" class="dialog--import" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/import.svg" class="dialog-header-icon" alt="">
        Импорт слов из файла .xlsx
      </div>

      <q-banner v-if="error" inline-actions class="text-white bg-red">{{ error }}</q-banner>

      <q-form @submit.prevent="onSubmit">
        <template v-if="fileId === null">
          <p class="import-file__description">
            Выберите или перетащите файл в формате .xlsx
          </p>

          <q-file
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            v-model="file"
            @update:model-value="uploadFile"
            class="import-file"
            borderless
            :disable="fileUploading"
            :loading="fileUploading"
          >
            <template v-slot:file></template>
          </q-file>
        </template>

        <template v-if="fileId !== null">
          <p>
            В файле {{ pluralize(lines.length, 'обнаружен', 'обнаружено', 'обнаружено') }} {{ lines.length }} {{ pluralize(lines.length, 'слово', 'слова', 'слов') }}.<br>
            Вы можете выбрать слова, которые хотите добавить в список слов.
          </p>

          <div class="lines-header">
            <label class="lines-header__label">
              <q-checkbox
                :model-value="allChecked"
                class="lines-header__checkbox"
                @update:model-value="checkAll"
                :disable="processing"
              />
            </label>
            Выбрано: {{ checkedCount }}
          </div>
          <q-virtual-scroll
            style="max-height: 300px;"
            :items="lines"
            class="lines"
          >
            <template v-slot="{ item: line }">
              <div class="line">
                <div class="line__header">
                  <label>
                    <q-checkbox v-model="line.checked" class="line__checkbox" :disable="processing" />
                    <span class="line__name">{{ line.name }}</span>
                  </label>
                </div>
                <div class="line__teaser">
                  {{ line.teaser }}
                </div>
                <div v-if="line.similarTerms !== null" class="line__similar">
                  <q-btn flat label="Дубль">
                    <q-menu class="similar-terms" anchor="top right" self="top left" :offset="[8, 25]">
                      <div v-for="term in line.similarTerms" :key="term.id" class="similar-term">
                        <div class="similar-term__header">
                          <router-link :to="{ name:'terms_view', params: { id: term.id }}">{{ term.name }}</router-link>
                        </div>
                        <div v-if="term.teaser" class="similar-term__teaser">
                          {{ term.teaser }}
                        </div>
                      </div>
                    </q-menu>
                  </q-btn>
                </div>
              </div>
            </template>
          </q-virtual-scroll>
        </template>

        <div class="dialog-actions">
          <q-btn label="Отменить" flat class="cancel-button" @click.prevent="onCancelClick" />
          <q-btn label="Импортировать" type="submit" color="primary" :disabled="checkedCount === 0" :loading="processing" />
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { api as termsApi } from '../../api/terms.js'

export default {
  props: {
    hook: {
      type: Function,
      required: false,
    },
  },
  emits: ['ok'],
  data() {
    return {
      processing: false,
      error: null,

      file: null,
      fileUploading: false,

      fileId: null,
      lines: null,
    }
  },
  methods: {
    show () {
      this.$refs.dialog.show()
    },
    hide () {
      this.$refs.dialog.hide()
    },
    onCancelClick () {
      this.hide()
    },
    uploadFile() {
      this.fileUploading = true
      termsApi.uploadImportFile(this.file)
        .then(result => {
          this.fileUploading = false

          this.fileId = result.id

          result.lines.forEach(line => {
            line.checked = false
            line.similarTermsVisible = false
          })
          this.lines = result.lines
        })
    },
    checkAll() {
      if (this.allChecked) {
        this.lines.forEach(line => {
          line.checked = false
        })
      } else {
        this.lines.forEach(line => {
          line.checked = true
        })
      }
    },
    onSubmit() {
      this.processing = true

      const linesNumbers = this.lines.filter(line => line.checked).map(line => line.number)
      termsApi.importTerms(this.fileId, linesNumbers).then(() => {
        this.processing = false

        const importedLines = this.lines.filter(line => line.checked)
        if (this.hook) {
          this.hook().then(() => {
            this.$emit('ok', importedLines)
            this.hide()
          })
        } else {
          this.$emit('ok', importedLines)
          this.hide()
        }
      })
    },
  },
  computed: {
    checkedCount() {
      return this.lines ? this.lines.filter(line => line.checked).length : 0
    },
    allChecked() {
      const checkedCount = this.checkedCount
      const linesLength = this.lines.length
      if (checkedCount === linesLength) {
        return true
      } else if (checkedCount === 0) {
        return false
      }
      return null
    },
  }
}
</script>

<style lang="scss">
.dialog--import {
  .q-dialog__inner > .dialog-content {
    width: 652px;
    max-width: 652px;
  }

  .dialog-actions {
    margin-top: 60px;
  }
}

.import-file__description {
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.53);
}

.import-file {
  display: block;
  background: rgba(44, 125, 220, 0.11);
  border: 1px dashed #2C7DDC;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  color: #2C7DDC;

  .q-field__native::before {
    content: "+ Выбрать файл";
    margin: auto;
    font-size: 16px;
    color: #2C7DDC;
  }

  .q-field__append {
    position: absolute;
    right: 20px;
  }

  input {
    display: none;
  }
}

.lines-header {
  position: relative;
  width: 480px;
  border: 1px solid #EBEBEB;
  padding: 10px 10px 10px 46px;
  border-radius: 5px 5px 0 0;

  &__checkbox {
    position: absolute;
    top: 4px;
    left: 4px;
  }
}
.lines {
  width: 550px;
}
.line {
  border: 1px solid #EBEBEB;
  padding-bottom: 7px;
  padding-right: 5px;
  width: 480px;

  &__header {
    position: relative;
    padding: 10px 10px 5px 46px;
  }

  &__checkbox {
    position: absolute;
    top: 4px;
    left: 4px;
  }

  &__name {
    font-size: 15px;
    line-height: 20px;
  }

  &__teaser {
    padding-left: 46px;
    padding-bottom: 3px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;

    color: rgba(0, 0, 0, 0.5);
  }

  &__similar {
    position: relative;
    text-align: right;
    padding: 0;

    .q-btn {
      font-size: 11px;
      color: #2C7DDC;
      padding: 0 7px;
    }
  }
}
@media (max-width: 750px) {
  .lines-header {
    width: 100%;
  }
  .lines {
    width: 100%;
  }
  .line {
    width: 100%;
  }
}

.similar-terms {
  width: 300px;
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.similar-term {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  &__header {
    color: #2C7DDC;
  }

  &__teaser {
    margin-top: 10px;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
